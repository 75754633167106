import { render, staticRenderFns } from "./ExplanatoryVideo.vue?vue&type=template&id=a3e54faa&scoped=true&lang=pug&"
import script from "./ExplanatoryVideo.vue?vue&type=script&lang=ts&"
export * from "./ExplanatoryVideo.vue?vue&type=script&lang=ts&"
import style0 from "./ExplanatoryVideo.vue?vue&type=style&index=0&id=a3e54faa&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e54faa",
  null
  
)

export default component.exports